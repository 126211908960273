// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$alleo-replica-primary: mat.define-palette(mat.$indigo-palette);
$alleo-replica-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$alleo-replica-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$alleo-replica-theme: mat.define-light-theme(
  (
    color: (
      primary: $alleo-replica-primary,
      accent: $alleo-replica-accent,
      warn: $alleo-replica-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($alleo-replica-theme);

/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(./assets/scss/fonts.scss);
@import url(./assets/scss/icons.scss);

* {
  font-family: Alleo-Lato, Alleo-Fractions, appleLogo, sans-serif;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Alleo-Lato, Alleo-Fractions, appleLogo, sans-serif;
}

*:has(> .show-on-hover) {
  position: relative;
}

*:has(> .show-on-hover):hover .show-on-hover {
  display: inherit !important;
}

.show-on-hover {
  display: none !important;
}

.mat-mdc-menu-content {
  background-color: #f8f8f8;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  max-width: unset !important;
}

.c-switch {
  display: inline-block;
  line-height: 0;
  position: relative;
}

.c-switch__input {
  --saf-0: #1d1c1d80;
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid #1d1c1d80;
  border-radius: 18px;
  cursor: pointer;
  height: 30px;
  margin: 0;
  outline: none;
  transition: background-color 0.1s ease-in;
  width: 54px;
}

.c-switch__input:checked {
  background-color: #007a5a;
  border: none;
}

.c-switch__visual {
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
}

.c-switch__input:checked + .c-switch__visual:before {
  color: #fff;
  opacity: 1;
  position: absolute;
}

.c-switch__visual:before {
  content: "\E704";
  display: inline-block;
  font-family: Alleo v2;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  left: 2px;
  line-height: 1;
  opacity: 0;
  position: absolute;
  top: 2px;
  transition: opacity 0.1s ease-in;
}

.c-switch__input:checked + .c-switch__visual:after {
  background-color: #fff;
  transform: translate3d(22px, 0, 0);
}

.c-switch__visual:after {
  background-color: rgba(var(--sk_foreground_max, 29, 28, 29), 0.7);
  border-radius: 100%;
  content: "";
  height: 20px;
  left: 6px;
  position: absolute;
  top: 5px;
  transform: translateZ(0);
  transform-origin: center;
  transition: transform 0.1s ease-in, background-color 0.1s ease-in;
  width: 20px;
}

.truncated-text {
  --lines: 1;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--lines);
  display: -webkit-box;
  overflow: hidden;
  white-space: break-spaces;
}

.mat-mdc-dialog-content.mdc-dialog__content {
  overflow-x: hidden;
}

[lang] body {
  font-family: Alleo-Lato, Alleo-Fractions, appleLogo, sans-serif;
}
[lang="ja-JP"] body {
  font-family: NotoSansJP, Alleo-Lato, Alleo-Fractions, appleLogo, sans-serif;
}
[lang="ko-KR"] body {
  font-family: NotoSansKR, Alleo-Lato, Alleo-Fractions, appleLogo, sans-serif;
}
[lang="zh-CN"] body {
  font-family: NotoSansSC, Alleo-Lato, Alleo-Fractions, appleLogo, sans-serif;
}
[lang="zh-TW"] body {
  font-family: NotoSansTC, Alleo-Lato, Alleo-Fractions, appleLogo, sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #898989;
  border-radius: 9999px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #898989;
}
