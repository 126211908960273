.c-icon::before {
  display: inline-block;
  font-family: Alleo v2;
  font-size: inherit;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.c-icon-sparkle::before {
  font-size: 14px;
  content: "\E123";
}

.c-icon-globe::before {
  font-size: 14px;
  content: "\E085";
}

.c-icon-down::before {
  font-size: 14px;
  content: "\E256";
}

.c-action-icon::before {
  font-size: 14px;
  content: "\E094";
}

.c-icon-search::before {
  content: "\E01B";
}

.c-icon-sort::before {
  content: "\E31B";
}

.c-icon-checked::before {
  content: "\E285";
}

.c-icon-info::before {
  content: "\E008";
}

.c-icon-sidepane::before {
  content: "\E145";
}

.c-icon-delete::before {
  content: "\E045";
}
