@font-face {
  font-display: swap;
  font-family: Alleo-Lato;
  font-style: normal;
  font-weight: 300;
  src: local("Alleo-Lato"), url(../fonts/lato-light.woff2) format("woff2"),
    url(../fonts/lato-light.woff) format("woff");
  unicode-range: U+0000-F8FE, U+F900-FFFF;
}
@font-face {
  font-display: swap;
  font-family: Alleo-Lato;
  font-style: normal;
  font-weight: 400;
  src: local("Alleo-Lato"), url(../fonts/lato-regular.woff2) format("woff2"),
    url(../fonts/lato-regular.woff) format("woff");
  unicode-range: U+0000-F8FE, U+F900-FFFF;
}
@font-face {
  font-display: swap;
  font-family: Alleo-Lato;
  font-style: normal;
  font-weight: 700;
  src: local("Alleo-Lato"), url(../fonts/lato-bold.woff2) format("woff2"),
    url(../fonts/lato-bold.woff) format("woff");
  unicode-range: U+0000-F8FE, U+F900-FFFF;
}
@font-face {
  font-display: swap;
  font-family: Alleo-Lato;
  font-style: normal;
  font-weight: 900;
  src: local("Alleo-Lato"), url(../fonts/lato-black.woff2) format("woff2"),
    url(../fonts/lato-black.woff) format("woff");
  unicode-range: U+0000-F8FE, U+F900-FFFF;
}
@font-face {
  font-display: swap;
  font-family: Alleo-Lato;
  font-style: italic;
  font-weight: 300;
  src: local("Alleo-Lato"),
    url(../fonts/lato-italic-light.woff2) format("woff2"),
    url(../fonts/lato-italic-light.woff) format("woff");
  unicode-range: U+0000-F8FE, U+F900-FFFF;
}
@font-face {
  font-display: swap;
  font-family: Alleo-Lato;
  font-style: italic;
  font-weight: 400;
  src: local("Alleo-Lato"), url(../fonts/lato-italic.woff2) format("woff2"),
    url(../fonts/lato-italic.woff) format("woff");
  unicode-range: U+0000-F8FE, U+F900-FFFF;
}
@font-face {
  font-display: swap;
  font-family: Alleo-Lato;
  font-style: italic;
  font-weight: 700;
  src: local("Alleo-Lato"), url(../fonts/lato-italic-bold.woff2) format("woff2"),
    url(../fonts/lato-italic-bold.woff) format("woff");
  unicode-range: U+0000-F8FE, U+F900-FFFF;
}
@font-face {
  font-display: swap;
  font-family: Alleo-Lato;
  font-style: italic;
  font-weight: 900;
  src: local("Alleo-Lato"),
    url(../fonts/lato-italic-black.woff2) format("woff2"),
    url(../fonts/lato-italic-black.woff) format("woff");
  unicode-range: U+0000-F8FE, U+F900-FFFF;
}
@font-face {
  font-family: Alleo-Larsseit;
  font-style: normal;
  font-weight: 700;
  src: local("☺"), url(../fonts/larsseit-bold.woff2) format("woff2"),
    url(../fonts/larsseit-bold.woff) format("woff");
  unicode-range: U+0000-F8FE, U+F900-FFFF;
}
@font-face {
  font-family: Alleo-Larsseit;
  font-style: normal;
  font-weight: 400;
  src: local("☺"), url(../fonts/larsseit-regular.woff2) format("woff2"),
    url(../fonts/larsseit-regular.woff) format("woff");
  unicode-range: U+0000-F8FE, U+F900-FFFF;
}
@font-face {
  font-family: Alleo v2;
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/alleo-icons-v2-fe043a5.woff2) format("woff2"),
    url(../fonts/alleo-icons-v2-f1c27d6.woff) format("woff");
}
